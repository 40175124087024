import React, { Component } from 'react';
import { Form, Button, Image, Icon, Message, Dimmer, Loader, Popup, Container } from 'semantic-ui-react';
import { Row, Column } from '../components/common/grid';
import Axios from 'axios';
import { API_URL } from '../constants/constants';
import { navigate } from 'gatsby';
// @ts-ignore
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import '../components/style.less';

class Apply extends Component {
  state = {
    name: '',
    email: '',
    nationality: '',
    degree: false,
    teachingExperience: false,
    currentLocation: '',
    message: '',
    loading: false,
    errors: false,
    errorMessage: '',
    job: {
      jobTitle: '',
      name: '',
      city: '',
      country: ''
    }
  };

  componentDidMount = async () => {
    const id = this.props.location.href.split('=')[1];
    const res = await fetch(`${API_URL}/job?jobId=` + id);
    const data = await res.json();
    this.setState({ job: data.job, loading: false });
  };

  handleApply = async () => {
    this.setState({ loading: true, errors: false, errorMessage: '' });

    const { name, email, nationality, degree, teachingExperience, currentLocation, message, job } = this.state;

    if (
      name.trim().length > 1 &&
      email.trim().length > 1 &&
      nationality.trim().length > 1 &&
      degree !== undefined &&
      currentLocation.trim().length > 1 &&
      message.trim().length > 1 &&
      teachingExperience !== undefined
    ) {
      const application = {
        name,
        email,
        nationality,
        degree,
        teachingExperience,
        currentLocation,
        message,
        job
      };
      Axios.post(`${API_URL}/apply`, application).then(response => {
        if (response.data.success) {
          toast.success('Successfully applied');
          this.setState({ loading: false });
          navigate('/');
        } else {
          toast.error('Error applying');
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({
        errors: true,
        errorMessage: 'Something broke',
        loading: false
      });
    }
  };

  render() {
    return this.state.job ? (
      <Container className='container'>
        <Helmet
          title={`eslbot | apply for ${this.state.job.jobTitle}`}
          meta={[
            { name: 'description', content: `Apply` },
            {
              name: 'keywords',
              content: `tefl tesol esl apply job`
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover'
            }
          ]}
        />
        <Row>
          <Column style={{ flex: 4 }}>
            <div style={{ textAlign: 'center' }}>
              <Image
                className='apply-image'
                wrapped
                size='small'
                src='https://upload.wikimedia.org/wikipedia/commons/3/38/Robot-clip-art-book-covers-feJCV3-clipart.png'
              />
            </div>
            <div className='apply-job-details'>
              <h4 style={{ marginBottom: '5px' }}>You're applying for</h4>
              <p>{this.state.job.jobTitle}</p>
              <p>
                <Icon name='building' />
                {this.state.job.name}
              </p>
              <p>
                <Icon name='globe' />
                {this.state.job.city}, {this.state.job.country}
              </p>
            </div>
          </Column>
          <Column style={{ flex: 8 }}>
            <Form>
              <Row>
                <Column>
                  <Form.Input
                    label='Your Name'
                    type='text'
                    placeholder='Lloyd Bonafide'
                    onChange={event =>
                      this.setState({
                        name: event.target.value
                      })
                    }
                  />
                </Column>
                <Column>
                  <Form.Input
                    label='Your Email Address'
                    type='text'
                    placeholder='email@domain.com'
                    onChange={event =>
                      this.setState({
                        email: event.target.value
                      })
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Form.Input
                    name='nationality'
                    type='text'
                    placeholder='American'
                    label="What's your nationality?"
                    onChange={event =>
                      this.setState({
                        nationality: event.target.value
                      })
                    }
                  />
                </Column>
                <Column>
                  <Form.Input
                    name='currentLocation'
                    type='text'
                    placeholder='Japan'
                    label='Where do you currently live?'
                    onChange={event =>
                      this.setState({
                        currentLocation: event.target.value
                      })
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Form.Select
                    label='Do you have a 4-year university degree?'
                    onChange={({}, { value }) => {
                      this.setState({ degree: value });
                    }}
                    options={[
                      {
                        key: 'Yes',
                        value: true,
                        text: 'Yes'
                      },
                      {
                        key: 'No',
                        value: false,
                        text: 'No'
                      }
                    ]}
                  />
                </Column>
                <Column>
                  <Form.Select
                    label='Do you have teaching experience??'
                    onChange={({}, { value }) => {
                      this.setState({ teachingExperience: value });
                    }}
                    options={[
                      {
                        key: 'Yes',
                        value: true,
                        text: 'Yes'
                      },
                      {
                        key: 'No',
                        value: false,
                        text: 'No'
                      }
                    ]}
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Form.TextArea
                    name='message'
                    label='Write a message to the employer'
                    placeholder="Include information about your experience, education, nationality, and personality. Be careful about providing detailed personal information like identification numbers. Wait until you've received a response, at least."
                    onChange={() =>
                      this.setState({
                        message: event.target.value
                      })
                    }
                  />
                </Column>
              </Row>
            </Form>
            <br />
            <div style={{ textAlign: 'center' }}>
              {this.state.errors ? <Message negative>All Fields Are Required</Message> : null}
              <Button primary onClick={this.handleApply}>
                Apply now
              </Button>
              <br />
              <br />
              <Popup
                content='When you apply, we send an email to the job poster with the information you filled out in the form above. We do not save your data.'
                trigger={<span style={{ color: '#3072B3' }}>How does applying work?</span>}
              />
            </div>
          </Column>
        </Row>
        <Dimmer inverted active={this.state.loading}>
          <Loader content='Emailing job poster' />
        </Dimmer>
      </Container>
    ) : (
      <p>Loading</p>
    );
  }
}

export default Apply;
